import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
// import "rxjs/add/operator/do";
// import "rxjs/add/operator/finally";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUserV2 = <any>JSON.parse(localStorage.getItem("currentUserV2"));
    const headers = <any>{
      "Content-Type": "application/json; charset=utf-8",
    };

    if (localStorage.getItem("ipClient")) {
      headers["Remote-IP"] = localStorage.getItem("ipClient");
      headers["X-Remote-IP"] = localStorage.getItem("ipClient");
    }

    if (currentUserV2 && currentUserV2.manager && currentUserV2.manager.u_token) {
      headers["X-Mng-Token"] = currentUserV2.manager.u_token;
    }

    if (
      currentUserV2 &&
      currentUserV2.token &&
      !headers.hasOwnProperty("Authorization")
    ) {
      headers["Authorization"] = "Bearer " + currentUserV2.token;

      request = request.clone({
        setHeaders: headers,
      });
    } else {
      request = request.clone({
        setHeaders: headers,
      });
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (
            err.error.error &&
            (err.error.message == "Token has expired" ||
              err.error.message == "access_denied" ||
              err.error.error == "token_not_provided" ||
              err.error.error == "token_expired" ||
              err.error.error == "token_invalid" ||
              err.error.error == "user_not_found")
          ) {
            this.router.navigate(["/login"]);
          }
        }
        return throwError(err);
      })
    );
    //      .finally(() =>
    //        document.querySelector('#animationload').setAttribute('style', 'display:none'
    //      ) );
  }
}
