<div class="manual-verification__container">
  <form name="form" autocomplete="nope" [formGroup]="verifyPhoneFormGroup">
    <div>
      <div class="manual-verification__header">
        <h4 class="text-center">
          {{ messages.get("PLEASE_VERIFY_YOUR_PHONE") }}
        </h4>
        <p *ngIf="!visiblePhone()" class="manual-verification__header__text text-center">
          {{ messages.get("TEXT_MESSAGE_OR_PHONE_CALL") }}
        </p>
        <div *ngIf="!visiblePhone()" class="row justify-content-center">
          <div class="col-sm-11">
            <div class="form-group" [ngClass]="{ 'has-error has-danger': verifyPhoneFormGroup.get('phone').errors
                        && (verifyPhoneFormGroup.get('phone').dirty || verifyPhoneFormGroup.get('phone').touched) }">
              <div class="input-group">
                <input class="form-control ng-valid ng-touched ng-dirty"
                       placeholder="Please enter your phone" type="text" formControlName="phone"
                       required [textMask]="{mask: utility.maskPhone}" id="phone" name="phone">

              </div>
              <div *ngIf="verifyPhoneFormGroup.get('phone').errors &&
                    (verifyPhoneFormGroup.get('phone').dirty || verifyPhoneFormGroup.get('phone').touched)"
                   class="help-block form-text with-errors form-control-feedback">
                    <span *ngIf="verifyPhoneFormGroup.get('phone').errors.validPhone">
                      Phone must be at least 10 digits long.
                    </span>
                    <span *ngIf="verifyPhoneFormGroup.get('phone').errors.required">
                      Phone is required
                    </span>
              </div>
            </div>
          </div>
        </div>
        <p *ngIf="visiblePhone()" class="manual-verification__header__text text-center">
          Please select Text Message or Phone Call and verify your phone <b>{{objExistUser.masked_phone || objPhoneMask}}</b>
        </p>
        <p class="manual-verification__header__text text-center">
          How would you like to receive your verification code?
        </p>
      </div>
    </div>

    <div>

      <div class="">
        <div class="d-flex justify-content-center">
          <button style="margin-left: 1rem;" class="mr-3 mb-3 btn btn-primary btn-rounded btn-lg"
                  [ngClass]="{'btn-primary': phoneObj.phoneButtonClass, 'btn-success': !phoneObj.phoneButtonClass}"
                  type="button" (click)="sendPhonePinCode('TextMessage')" [disabled]="verifyPhoneFormGroup.get('phone').errors && !objExistUser.masked_phone && !objExistUser.phone || timerIsLoadding">
            Text Message
          </button>
          <button class="mr-3 mb-3 btn btn-primary btn-rounded btn-lg" type="button"
                  [ngClass]="{'btn-primary': phoneObj.textMessageButtonClass, 'btn-success': !phoneObj.textMessageButtonClass}"
                  (click)="sendPhonePinCode('PhoneCall')" [disabled]="verifyPhoneFormGroup.get('phone').errors && !objExistUser.masked_phone && !objExistUser.phone || timerIsLoadding">
            Phone Call
            <i aria-hidden="true" class="fa fa-question-circle" placement="top"
               ngbTooltip="If you have a robo call blocker on your landline this option will not work">
            </i>
          </button>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-11">
            <div class="form-group" [ngClass]="{ 'has-error has-danger': verifyPhoneFormGroup.get('phone_pin').errors
                        && (verifyPhoneFormGroup.get('phone_pin').dirty || verifyPhoneFormGroup.get('phone_pin').touched) }">
              <div class="input-group">
                <input *ngIf="phoneObj.phoneSuccess" class="form-control ng-valid ng-touched ng-dirty" maxlength="6" formControlName="phone_pin"
                       placeholder="Verification Code"  type="text" id="phone_pin" name="phone_pin">
                <span style="padding: 10px;color: #58a6f9;" *ngIf="timerIsLoadding">CODE SENT <i class="picons-thin-icon-thin-0317_send_post_paper_plane"></i></span>
              </div>
              <div *ngIf="verifyPhoneFormGroup.get('phone_pin').errors &&
                    (verifyPhoneFormGroup.get('phone_pin').dirty || verifyPhoneFormGroup.get('phone_pin').touched)"
                   class="help-block form-text with-errors form-control-feedback">
                    <span *ngIf="verifyPhoneFormGroup.get('phone_pin').errors.maxlength">
                      Phone verification code must be at least 6 digits long.
                    </span>
                    <span *ngIf="verifyPhoneFormGroup.get('phone_pin').errors.required">
                      Phone verification code is required
                    </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center  digital-buttonsV2">
        <button type="button" class="btn btn-link slick-prev slick-arrow" style="bottom: 0;" (click)="returnToPreviosStep()">
          Back
        </button>
        <button type="button" style="width: 160px;" class="btn btn-outline-primary btn-rounded ng-star-inserted" (click)="resendVerifyCode()" [disabled]="!loaded || timerIsLoadding">
          RE-SEND CODE <span [innerHTML]="countTimer"></span>
        </button>
        <button type="submit" class="btn btn-link slick-next slick-arrow" style="bottom: 0;" (click)="verifyPhoneCode()" [disabled]="verifyPhoneFormGroup.get('phone_pin').errors">
          Verify
        </button>
      </div>
      <br>
      <div class="justify-content-center">
        <div
          *ngIf="errorService.isError"
          class="alert alert-danger w-100 text-center"
          role="alert"
          [innerHTML]="errorService.errorMessage">
        </div>
        <div
          *ngIf="errorService.isSuccess"
          class="alert alert-info-custom w-100 text-center"
          role="alert"
          [innerHTML]="errorService.successMessage">
        </div>
      </div>
    </div>
  </form>
</div>
