import { Injectable } from '@angular/core';
import {UserService} from './user.service';
import {CurrencyPipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private ban_void: number;

  constructor(
    public userService: UserService,
    private currencyPipe: CurrencyPipe,
  ) { }


  canVoidCheck(status: string = '', strType: string = '', transactionObj: any = {}, ifTransactionObj: boolean = false) {
    if ( strType === 'internal' && (this.userService.isMerchant() || this.userService.isClient() ) ) {
      return false;
    }
    let canVoid: boolean;
    canVoid = true;
    switch (status.toLowerCase() ) {
      case 'paid':
      case 'voided':
      case 'void pending':
      case 'cancel pending':
      case 'cancelled':
      case 'processing':
        if(transactionObj.is_same_day || transactionObj.is_rtp || transactionObj.delayed){
          canVoid = false;
        }
        break;
      case 'processed':
      case 'failed':
      case 'printed':
      case 'delayed':
        canVoid = false;
        break;
      case 'unpaid':
      case 'pending':
        if (transactionObj.ban_void == 1) {
          canVoid = false;
        } else {
          canVoid = ifTransactionObj ? transactionObj.u_token == this.userService.getToken() : true;
        }
        /*canVoid = true;*/
        break;
      default:
        if (transactionObj.ban_void == 1) {
          canVoid = false;
        } else {
          canVoid = ifTransactionObj ? transactionObj.u_token == this.userService.getToken() : true;
        }
        /*canVoid = true;*/
        break;
    }
    return canVoid;
  }

  canApproveCheck(check){
    if(!(this.userService.isSuperAdmin() || this.userService.isAdmin() || this.userService.isMerchant())){
      return false;
    }
    if(check.status == 'Paid'){
      return false;
    }
    if((check.machine_learning?.risk > 70 && check.rec_name != 'Paynote' && check.type == 'recurring (invoice)') || check.status == 'hold'){
      return true;
    }
    return false
  }

  canApproveCheckMerchant(check){
    if(!(this.userService.isSuperAdmin() || this.userService.isAdmin() || this.userService.isMerchant())){
      return false;
    }
    if (check.status == 'hold' && check.signal != null) {
      return true;
    }
    return false
  }

  canResendNotifications(transactionOdj: any = null) {
    if (transactionOdj.type == 'internal') {
      return false;
    }
    if ( this.userService.isClient() ) {
      return false;
    }
    if (transactionOdj.type == 'check' || transactionOdj.type == 'recurring (check)') {
      return transactionOdj.status == 'unpaid'
        && (transactionOdj.type == 'check' || transactionOdj.type == 'recurring (check)')
        && transactionOdj.sndr_email.toLocaleLowerCase() == this.userService.getEmail().toLocaleLowerCase();
    }
    if ( transactionOdj.type == 'invoice' || transactionOdj.type == 'recurring (invoice)' ) {
      return transactionOdj.status == 'unpaid'
        && (transactionOdj.type == 'invoice' || transactionOdj.type == 'recurring (invoice)')
        && transactionOdj.rec_email.toLocaleLowerCase() == this.userService.getEmail().toLocaleLowerCase();
    }
  }

  isSystemPaiment( transaction: any = null ) {
    if (!transaction ) {
      return false;
    }

    if (this.userService.isAdmin() || this.userService.isSuperAdmin() ) {
      return false;
    }

    return transaction.rec_token && transaction.rec_token.indexOf('####') >= 0;
  }

  renamePaymentType(type: string = ''){
    let newTypeName = type;
    switch (type.toLowerCase() ) {
      case 'check':
        newTypeName = 'Credit';
        break;
      case 'invoice':
        newTypeName = 'Debit';
        break;
      case 'recurring (check)':
      case 'recurring(check)':  
        newTypeName = 'Recurring (Credit)';
        break; 
      case 'recurring (invoice)':
      case 'recurring(invoice)':        
        newTypeName = 'Recurring (Debit)';
        break;
      case 'internal':
        newTypeName = 'Internal';
        break;
    }

    return newTypeName;
  }

  canCancelInvoice(status: string = '', strType: string = '' ) {
    if ( strType === 'internal' && (this.userService.isMerchant() || this.userService.isClient() ) ) {
      return false;
    }
    let canVoid = true;
    switch (status.toLowerCase() ) {
      case 'paid':
      case 'voided':
      case 'void pending':
      case 'cancel pending':
      case 'cancelled':
      case 'processed':
      case 'printed':
        canVoid = false;
        break;
      default:
        canVoid = true;
        break;
    }

    return canVoid;
  }

  isCheck( strType: string = '' ) {
    return strType == 'check' || strType == 'recurring (check)';
  }

  isInvoice( strType: string = ''  ) {
    return strType == 'invoice' || strType == 'recurring (invoice)';
  }

  getType( objTrans: any ) {
    return objTrans.type;
  }

  getStatus(transaction: any = {}, onlyLable: boolean = false) {
    let status = '';
    if (transaction.check_status) {
      transaction.status = transaction.check_status;
    }
    if (transaction.status && !onlyLable) {
      const firstPartMessage = 'The funds have been disbursed on ';
      // if ( this.userService.isSuperAdmin() || this.userService.isAdmin() || transaction.u_token == this.userService.getToken() ) {
      //   firstPartMessage = this.isCheck(transaction.type)
      //     ? 'The funds have been disbursed to the recipient on '
      //     : 'The funds have been disbursed to on ';
      // } else {
      //   firstPartMessage = this.isInvoice(transaction.type)
      //     ? 'The funds have been disbursed to the recipient on '
      //     : 'The funds have been disbursed to on ';
      // }
      switch (transaction.status.toLowerCase()) {
        case 'processed':
          status = firstPartMessage + this.userService.getDateFormat(transaction.payment_at)
            + '. You should see them the same day or next working day';
          break;
        case 'pending':
          status = 'In process';
          break;
        case 'cancelled':
        case 'voided':
        case 'void pending':
          status = 'Void';
          break;
        case 'failed':
          status = 'Failed';
          break;
        case 'printed':
          status = 'Printed';
          break;
        case 'hold':
          status = 'On-Hold';
          break;
        case 'declined':
        case 'decline':
          status = 'Declined';
          break;
        case 'unpaid':
        case 'new':
          status = 'Unpaid';
          break;
        case 'expired':
          status = 'Expired';
          break;
        case 'delayed':
          status = 'Delayed';
          break;
        case 'processing':
          status = 'Processing';
          break;
        case 'refunded':
          status = 'Refunded';
          break;
        case 'refund pending':
          status = 'Refund Pending';
          break;
        case 'refund failed':
          status = 'Refund Failed';
          break;
      }
    }

    let strLabels = '<br>';
    if ( transaction.labels && transaction.labels.length ) {
      transaction.labels.forEach(function (label: any) {
        strLabels += '<span class="badge badge-warning">' + label + '</span>';
      });
    }

    if (this.userService.isAdmin() || this.userService.isSuperAdmin()) {
      if (transaction.status == 'processed' && !onlyLable) {

      } else {
        status += strLabels;
      }
    }

    return status;
  }

  getAmountString(objTransaction: any = null) {
    let strAmount = '';
    if (!objTransaction) {
      return '';
    }
    if (this.userService.isMerchant()) {
      if (objTransaction.type === 'check' && this.userService.getToken() !== objTransaction.rec_token
        && this.userService.getEmail() !== objTransaction.rec_email) {
        strAmount += '-';
      }
      if (objTransaction.type === 'recurring (check)' && this.userService.getToken() !== objTransaction.rec_token
        && this.userService.getEmail() !== objTransaction.rec_email) {
        strAmount += '-';
      }
      if (objTransaction.type === 'invoice' && this.userService.getToken() === objTransaction.sndr_token
        && this.userService.getEmail() === objTransaction.sndr_email) {
        strAmount += '-';
      }
      if (objTransaction.type === 'recurring (invoice)' && this.userService.getToken() === objTransaction.sndr_token
        && this.userService.getEmail() === objTransaction.sndr_email) {
        strAmount += '-';
      }
    }
    if (this.userService.isClient()) {
      if (this.userService.getToken() !== objTransaction.rec_token && this.userService.getEmail() !== objTransaction.rec_email) {
        strAmount += '-';
      }
    }

    strAmount += this.currencyPipe.transform(objTransaction.amount, '', 'symbol');

    return strAmount;
  }

  getPrintedStatus(transaction: any = {}) {
    if (transaction.status && transaction.status == 'printed') {
      return 'Printed on ' + this.userService.getDateFormat(transaction.downloaded, 'MM/DD/YYYY');
    }
    return '';
  }

  getEstimatedDate(transaction: any = {}) {
    const message = 'The money will be paid in ';
    if (transaction.status && transaction.status == 'pending' && transaction.estimated_at ) {
      return message + this.userService.getDateFormat(transaction.estimated_at, 'MM/DD/YYYY') + '. You should see them the same day or the next business day.';
    }
    return '';
  }

  getTextForDelayed( objTrans: any ) {
    return 'Delayed ' + objTrans.delayed + ' business day' + (objTrans.delayed > 1 ? 's' : '');
  }

  getTransferType( objTrans: any ) {
    if ( this.isDelayed(objTrans) ) {
      return this.getTextForDelayed( objTrans );
    }
    if (objTrans.is_rtp == 1) {
      return 'Real Time';
    }
    if( objTrans.is_same_day == 1 ) {
      return 'Same Day';
    }

    return objTrans.is_nextday ? 'Next Day' : 'Standard';
  }

  canShowScheduledDate( objTrans: any ) {

  }

  getDateScheduled(  objTrans: any  ) {
    if ( this.isDelayed(objTrans) && this.isScheduled( objTrans ) ) {
      if ( objTrans.processing && objTrans.processing.date ) {
        return this.userService.getDateFormat( objTrans.processing.date );
      } else {
        const countDays = objTrans.delayed + 4;
        return this.userService.getDateFormatCustom(null, 'MMM D, YYYY', countDays );
      }
    }

    return '';
  }

  isDelayed( objTrans: any ) {
    return objTrans.delayed > 0;
  }
  isInternal( objTrans: any ) {
    return this.getType( objTrans ) == 'internal';
  }
  isScheduled( objTrans: any ) {
    return objTrans.status == 'processing';
  }
}
