import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

import {UserService} from '../../_services/user.service';
import {JqueryService} from '../../_services/jquery.service';
import {Utility} from '../../_helpers/utility';

import {MessagesService} from '../../_services/messages.service';
import {AuthenticationService} from '../../auth.service';
import {validatePhone} from '../../_vaidators/phone';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {TimerService} from '../../_services/timer.service';


@Component({
  selector: 'app-password-setup',
  templateUrl: './password-setup.component.html',
  styleUrls: ['./password-setup.component.css']
})
export class PasswordSetupComponent implements OnInit {

  public host: string = environment.host;
  public loading = false;
  public setupPasswordForm: FormGroup;
  public canCloseModal = false;
  public isPhoneDisabled = false;
  public isSendTextMessage = false;
  public isSendVoiceMessage = false;
  public barLabel = '';
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public maskPhone: any = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public objDigitCheck: any = {
    verifyCodeSend: false,
    bankAccountType: 'checking',
    acceptPolicy: false,
    accountType: '',
    account_id: 0
  };
  public objInvoice: any = {};

  constructor(
    private http: HttpClient,
    private userService: UserService,
    public jqueryService: JqueryService,
    private router: Router,
    private _formBuilder: FormBuilder,
    public utility: Utility,
    public messages: MessagesService,
    private authenticationService: AuthenticationService,
    public topAlertsService: TopAlertsService,
    public timerService: TimerService
  ) {}

  ngOnInit() {
    this.timerService.initTimerCD('down');
    this.userService.initUser();
    this.jqueryService.removeSelector('.modal-backdrop.fade');
    this.jqueryService.removeSelector('.modal.fade.show.d-block');

    this.setupPasswordForm = this._formBuilder.group({
      // password: ['', Validators.compose([
      //   Validators.required,
      //   Validators.minLength(8)
      // ])
      // ],
      // cpassword: ['', Validators.compose([
      //   Validators.required,
      //   Validators.minLength(8)
      // ])
      // ],
      u_token: [
        this.userService.getToken()
      ],
      phone: ['', Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
        validatePhone
        //        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
      ])
      ],
      phone_pin: [],
      // phone_pin: ['', Validators.compose([
      //   Validators.required,
      //   Validators.minLength(6),
      //   Validators.maxLength(6)])
      // ]
    });
    if (this.userService.getPhone()) {
      this.setupPasswordForm.get('phone').disable();
      this.setupPasswordForm.get('phone').setValue(this.userService.getPhone());
      this.isPhoneDisabled = true;
    }
  }

  setupPasswordPhoneRegister( event: any = null) {
    let bValid = false;
    if ( event && event.key == 'Enter' && event.keyCode == 13 && this.setupPasswordForm.valid ) {
      bValid = true;
    }
    if ( !event && this.setupPasswordForm.valid ) {
      bValid = true;
    }
    if ( !bValid ) { return; }
    const objRequest = this.setupPasswordForm.value;
    if ( !objRequest.phone ) {
      objRequest.phone = this.setupPasswordForm.get('phone').value;
    }
    this.loading = true;
    this.authenticationService.setupPasswordAndPhone(objRequest)
      .subscribe(
        result => {
          this.loading = false;
          this.canCloseModal = true;
          const objResp = <any> result;
          this.topAlertsService.popToast(
            "success",
            "",
            this.messages.get("PLEASE_SET_YOUR_PASSWORD")
          );
          this.router.navigate(['login'])
        },
        errResponse => {
          this.loading = false;
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }



  sendVerifyCode() {
    if ( this.isSendTextMessage ) {
      this.sendVerifyTextCode();
    }
    if ( this.isSendVoiceMessage ) {
      this.sendVerifyVoiceCode();
    }
  }

  isShowOnLoad() {
    return false; // !environment.isSandBoxMode && this.userService.isCustomer() && !this.userService.isPasswordSet();
  }

  sendVerifyTextCode() {
    this.timerService.setTime(15, 's');
    this.authenticationService.sendVerifyCodeToTextMessage( this.setupPasswordForm.get('phone').value, this.userService.getEmail() )
      .subscribe(
        result => {
          const objResp = <any>result;
          if (objResp.success) {
            this.objDigitCheck.verifyCodeSend = true;
            this.isSendTextMessage = true;
            this.isSendVoiceMessage = false;
            setTimeout(() => this.jqueryService.setFocus('#phone_pin'), 200);
            this.topAlertsService.popToast('info', 'Attention', objResp.message);
          }
        },
        errResponse => {
          if ( errResponse.error ) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  sendVerifyVoiceCode() {
    this.timerService.setTime(15, 's');
    this.authenticationService.sendVerifyCodeToPhoneCall( this.setupPasswordForm.get('phone').value, this.userService.getEmail() )
      .subscribe(
        result => {
          const objResp = <any>result;
          if (objResp.success) {
            this.objDigitCheck.verifyCodeSend = true;
            this.isSendTextMessage = false;
            this.isSendVoiceMessage = true;
            setTimeout(() => this.jqueryService.setFocus('#phone_pin'), 200);
            this.topAlertsService.popToast('info', 'Attention', objResp.message);
          }
        },
        errResponse => {
          if ( errResponse.error ) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  availableSignUpByEmal() {
    return environment.availableSignUpByEmal;
  }

}
