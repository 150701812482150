import { Component, OnInit } from '@angular/core';


import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '../../auth.service';
import {Utility} from '../../_helpers/utility';
import {UserService} from '../../_services/user.service';
import { Spinkit } from 'ng-http-loader';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {Location} from '@angular/common';
import {NgxPlaidLinkService, PlaidLinkHandler} from 'ngx-plaid-link';
import {PlaidClientService} from '../../_services/plaid-client.service';
// declare var Plaid: any;

@Component({
  selector: 'app-express-checkout',
  templateUrl: './express-checkout.component.html',
  styleUrls: ['./express-checkout.component.css']
})
export class ExpressCheckoutComponent implements OnInit {

  public spinkit = Spinkit;
  public host: string = environment.host;
  public modalRef: NgbModalRef;
  public isLoading = false;
  public subDomen: string = environment.subDomen;
  public objExpressCheckoutComp: any = {
    objExprCheckRequest: <any> {},
    apiLinks: <any> environment.api,
    canShowExpressCheckout: <boolean> false
  };

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private router: ActivatedRoute,
    private _location: Location,
    public utility: Utility,
    public userService: UserService,
    public topAlertsService: TopAlertsService,
    public plaidService: PlaidClientService,
  ) { }

  ngOnInit() {
    this.userService.isExpressCheckout = true;
    const base64_token = this.router.snapshot.paramMap.get('base64_token');
    if ( base64_token ) {
      const objParamsIn = JSON.parse(atob(base64_token));
      console.log(objParamsIn);
      this.objExpressCheckoutComp.objExprCheckRequest = Object.assign(this.objExpressCheckoutComp.objExprCheckRequest, objParamsIn);
//      console.log(this.objExpressCheckoutComp.objExprCheckRequest)
      if ( this.objExpressCheckoutComp.objExprCheckRequest.lightBox ) {
        this.objExpressCheckoutComp.objExprCheckRequest.lightBox.redirectUrl =
          decodeURIComponent(this.objExpressCheckoutComp.objExprCheckRequest.lightBox.redirectUrl);
      }
      if (this.objExpressCheckoutComp.objExprCheckRequest.lightBox
        && this.objExpressCheckoutComp.objExprCheckRequest.lightBox.logoUrl != '' ) {
        this.objExpressCheckoutComp.objExprCheckRequest.lightBox.logoUrl =
          decodeURIComponent(this.objExpressCheckoutComp.objExprCheckRequest.lightBox.logoUrl);
      }
    }
    if (this.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails || !this.objExpressCheckoutComp.objExprCheckRequest.lightBox.show_cart_summary) {
      this.openPlaidModal();
    }
    setTimeout(() => {
      this.userService.clearUser();
      this.authenticationService.clearLogin();
    }, 2000);
  }

  onPlaidSuccess(event) {
    console.log( "objPalidCreds.onSuccess" )
    const vm = this;
    const publicToken = event.token
    const objAccountInfo = event.metadata
    const headers = {
      'Authorization': vm.objExpressCheckoutComp.objExprCheckRequest.publicKey
    };
    const Request = <any> {
      account_id: objAccountInfo.account_id,
      token: publicToken
    };
    if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerEmail) {
      Request.email = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerEmail;
    }
    if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerFirstName) {
      Request.firstName = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerFirstName;
    }
    if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerLastName) {
      Request.lastName = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerLastName;
    }
    if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.companyName) {
      Request.businessName = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.companyName;
    }
    if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.phone_number) {
      Request.phone_number = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.phone_number;
    }
    if (vm.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails) {
      Request.capture_auth = vm.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails;
    }

    vm.isLoading = true;
    vm.http.post<any>(vm.getHostApi() + '/user/express/', Request, {headers: headers})
      .subscribe(
        response => {
          if (response.success) {
            this.plaidService.plaidHandler.exit({ force: true });
            if (vm.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails) {
              if (vm.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'redirect') {
                const urlParams = new URL( vm.objExpressCheckoutComp.objExprCheckRequest.lightBox.redirectUrl );
                window.location.href =  urlParams.href;
              } else if (vm.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'iframe') {
                response.code = 'pay_success';
                parent.postMessage(response, '*');
              }
            } else {
              // vm.isLoading = false;
              vm.objExpressCheckoutComp.objExprCheckRequest = Object.assign(vm.objExpressCheckoutComp.objExprCheckRequest, response);
              // vm.userService.setFingerPrintBrowser();
              vm.submitPayment();
              // vm.objExpressCheckoutComp.canShowExpressCheckout = true;
            }
          }
        },
        errResponse => {
          vm.isLoading = false;
          if (errResponse.error) {
            vm.utility.getMessageError( errResponse.error );
            vm.topAlertsService.popToast('error', 'Error', vm.utility.errorMessage);
          }
        }
      );
  }

  onPlaidExit(event) {
    const vm = this;
    if (vm.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails) {
      if (vm.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'redirect') {
        vm._location.back();
        vm._location.back();
      } else if (vm.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'iframe') {
        vm.closeIframe();
      }
    }
    vm.isLoading = false;
  }

  openPlaidModal(modalContent?, backContent?) {
    this.isLoading = true;
    this.plaidService.init(this, {'key': this.objExpressCheckoutComp.objExprCheckRequest.publicKey}, modalContent, backContent)
      .then((plaidHandler: PlaidLinkHandler) => {
        this.isLoading = false;
        plaidHandler.open();
      });
  }

  // openDialogPlaid() {
  //   const objPalidCreds = <any> environment.plaid;
  //
  //   this.userService.setFingerPrintBrowser();
  //
  //   const vm = this;
  //   const headers = {
  //     'Authorization': vm.objExpressCheckoutComp.objExprCheckRequest.publicKey
  //   };
  //
  //   let plaidKey = '';
  //   this.http.get<any>(vm.host + '/dwl/customer/plaid/key', {params: {'key': this.objExpressCheckoutComp.objExprCheckRequest.publicKey}})
  //     .subscribe(
  //       keyResponse => {
  //         if (keyResponse.success) {
  //           plaidKey = keyResponse.key;
  //           if (plaidKey !== '') {
  //             objPalidCreds.clientName = keyResponse.name;
  //             objPalidCreds.key = plaidKey;
  //           }
  //         }
  //
  //         //objPalidCreds.receivedRedirectUri =
  //         objPalidCreds.onEvent = function(eventName: any, metadata: any) {
  //           // console.log( 'objPalidCreds.onEvent' )
  //           // console.log( eventName )
  //           // console.log( metadata )
  //           // window.alert( eventName )
  //         }
  //
  //         objPalidCreds.onSuccess = function (publickToken: string, objAccountInfo: any) {
  //           console.log( "objPalidCreds.onSuccess" )
  //           const Request = <any> {
  //             account_id: objAccountInfo.account_id,
  //             token: publickToken
  //           };
  //           if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerEmail) {
  //             Request.email = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerEmail;
  //           }
  //           if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerFirstName) {
  //             Request.firstName = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerFirstName;
  //           }
  //           if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerLastName) {
  //             Request.lastName = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.customerLastName;
  //           }
  //           if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.companyName) {
  //             Request.businessName = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.companyName;
  //           }
  //           if (vm.objExpressCheckoutComp.objExprCheckRequest.checkout.phone_number) {
  //             Request.phone_number = vm.objExpressCheckoutComp.objExprCheckRequest.checkout.phone_number;
  //           }
  //           if (vm.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails) {
  //             Request.capture_auth = vm.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails;
  //           }
  //
  //           vm.isLoading = true;
  //           vm.http.post<any>(vm.getHostApi() + '/user/express/', Request, {headers: headers})
  //             .subscribe(
  //               response => {
  //                 if (response.success) {
  //                   vm.plaidInstance.exit({ force: true });
  //                   if (vm.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails) {
  //                     if (vm.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'redirect') {
  //                       const urlParams = new URL( vm.objExpressCheckoutComp.objExprCheckRequest.lightBox.redirectUrl );
  //                       window.location.href =  urlParams.href;
  //                     } else if (vm.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'iframe') {
  //                       response.code = 'pay_success';
  //                       parent.postMessage(response, '*');
  //                     }
  //                   } else {
  //                     // vm.isLoading = false;
  //                     vm.objExpressCheckoutComp.objExprCheckRequest = Object.assign(vm.objExpressCheckoutComp.objExprCheckRequest, response);
  //                     vm.userService.setFingerPrintBrowser();
  //                     vm.submitPayment();
  //                     // vm.objExpressCheckoutComp.canShowExpressCheckout = true;
  //                   }
  //                 }
  //               },
  //               errResponse => {
  //                 vm.isLoading = false;
  //                 if (errResponse.error) {
  //                   vm.utility.getMessageError( errResponse.error );
  //                   vm.topAlertsService.popToast('error', 'Error', vm.utility.errorMessage);
  //                 }
  //               }
  //             );
  //         };
  //
  //         objPalidCreds.onExit = function () {
  //           console.log( "objPalidCreds.onExit" )
  //           if (vm.objExpressCheckoutComp.objExprCheckRequest.saveBankDetails) {
  //             if (vm.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'redirect') {
  //               vm._location.back();
  //               vm._location.back();
  //             } else if (vm.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'iframe') {
  //               vm.closeIframe();
  //             }
  //           }
  //           vm.isLoading = false;
  //         };
  //
  //         vm.plaidInstance = Plaid.create( objPalidCreds );
  //         vm.plaidInstance.open();
  //       }
  //     );
  // }

  submitPayment() {

    let amount = this.objExpressCheckoutComp.objExprCheckRequest.checkout.totalValue;
    if (environment.subDomen == 'sandbox' && amount >= 100 ) {
      amount = 98;
    }
    if (this.objExpressCheckoutComp.objExprCheckRequest.checkout.errorDemo) {
      amount = this.objExpressCheckoutComp.objExprCheckRequest.checkout.totalValue;
    }
    const headers = {
      'Authorization': this.objExpressCheckoutComp.objExprCheckRequest.publicKey
    };
    const Request = <any> {
      amount: amount,
      description: this.objExpressCheckoutComp.objExprCheckRequest.checkout.description,
      user_id: this.objExpressCheckoutComp.objExprCheckRequest.user.user_id,
      identifier: this.objExpressCheckoutComp.objExprCheckRequest.paymentToken
    };
    if ( this.objExpressCheckoutComp.objExprCheckRequest.checkout.recurring
      && this.objExpressCheckoutComp.objExprCheckRequest.checkout.recurring ) {
      Request.recurring = this.objExpressCheckoutComp.objExprCheckRequest.checkout.recurring;
    }
    this.http.post<any>(this.getHostApi() + '/check/express', Request, {headers: headers})
      .subscribe(
        response => {
          if (response.success) {
            if (this.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'redirect') {
            window.location.href =  this.getUrlParamsForRedirectUrl( this.objExpressCheckoutComp.objExprCheckRequest.lightBox.redirectUrl, response.check, amount);
            } else if (this.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'iframe') {
              response.code = 'pay_success';
              parent.postMessage(response, '*');
            }
          }
        },
        errResponse => {
          if (errResponse.error) {
            this.utility.getMessageError( errResponse.error );
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
            this.objExpressCheckoutComp.canShowExpressCheckout = false;
          }
        }
      );
  }

  getUrlParamsForRedirectUrl(url, check, amount ) {
      const urlParams = new URL( url );
      urlParams.searchParams.append("check_id", check.check_id);
      urlParams.searchParams.append("number", check.number);
      urlParams.searchParams.append("description", check.description);
      urlParams.searchParams.append("status", check.status);
      urlParams.searchParams.append("paymentToken", check.identifier);
      urlParams.searchParams.append("amount", check.amount);

      return urlParams.href;
  }

  getHostApi() {
    return this.objExpressCheckoutComp.objExprCheckRequest.sandbox
      ? this.objExpressCheckoutComp.apiLinks.sandbox_endpoint
      : this.objExpressCheckoutComp.apiLinks.live_endpoint;
  }

  getLogo() {
    return this.objExpressCheckoutComp.objExprCheckRequest.lightBox
      ? this.objExpressCheckoutComp.objExprCheckRequest.lightBox.logoUrl || 'assets/img/Paynote_-_New_Blue.svg'
      : 'assets/img/Paynote_-_New_Blue.svg';
  }

  closeIframe() {
    parent.postMessage({code:'pay_close'}, '*');
  }

  getReturnUrl() {
    return this.objExpressCheckoutComp.objExprCheckRequest.lightBox.cancelUrl;
  }

  getStoreName() {
    return this.objExpressCheckoutComp.objExprCheckRequest.storeName;
  }

  ifIframeMode() {
    return this.objExpressCheckoutComp.objExprCheckRequest.displayMethod == 'iframe';
  }

}
