import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, UrlSerializer } from '@angular/router';
import {MessagesService} from './_services/messages.service';
import {UserService} from './_services/user.service';
import {JqueryService} from './_services/jquery.service';
import {ToasterConfig} from 'angular2-toaster';
import {
  ExtendedGetResult,
  FingerprintjsProAngularService,
  GetResult,
} from '@fingerprintjs/fingerprintjs-pro-angular'

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<toaster-container [toasterconfig]="toasterConfig"></toaster-container><router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  previousUrl: string;
  public isApplyColorTheme = false;
  public fetchMessages = true;

  public toasterConfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    animation: 'fade',
    timeout: 15000,
    bodyOutputType: 1
  });

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private messages: MessagesService,
    public userService: UserService,
    private urlSerialise: UrlSerializer,
    public jqueryService: JqueryService,
    private fingerprintService: FingerprintjsProAngularService
  ) {
    this.router.events
      .subscribe((event) => {
//        this.initColorTheme()
        if (event instanceof NavigationStart) {
          // if (!!event.url && event.url.match(/^\/#/)) {
          //   this.router.navigate([event.url.replace('/#', '')]);
          //   return;
          // }

          const currentUrlSlug = this.getCurentSlug( event.url );

          this.parseGoogleCampaingSource( event.url );
          switch (currentUrlSlug ) {
            case 'login':
            case 'sign-up':
            case 'create-password':
            case 'reset-password':
            case 'status-page':
              this.setClassesForLogin();
              break;
            default:
              this.setClassesForLoginedUser();
              break;
          }

          if (this.hasSlugOnUrl(currentUrlSlug, 'express-checkout' ) >= 0 ) {
            this.fetchMessages = false;
            console.log( 'this.fetchMessages 1', this.fetchMessages )
            this.setClassesForExpresCheckout();
            return;
          }

          if ( this.hasSlugOnUrl( currentUrlSlug, 'recovery-password'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'create-password'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'sign-up'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'signup'  ) >= 0 ) {
            this.setClassesForLogin();
          }

          if ( this.hasSlugOnUrl( currentUrlSlug, 'check/'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'invoice/'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'checkout/'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'bank-account'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'confirm-email/'  ) >= 0 ) {
            this.setClassesForClient();
          }

          if (this.router.isActive('\login', true)
            || this.router.isActive('\sign-up', true) ) {
            this.setClassesForLogin();
          }
          if ( currentUrlSlug.indexOf('check/v2/') + 1 ) {
            this.classesForCheckV2();
          }
        }

        if (event instanceof NavigationEnd ) {
          const currentUrlSlug = this.getCurentSlug( event.url );
          switch (currentUrlSlug ) {
            case 'login':
            case 'sign-up':
            case 'recovery-password':
            case 'create-password':
            case 'reset-password':
            case 'status-page':
              this.setClassesForLogin();
              break;
            default:
              this.setClassesForLoginedUser();
              break;
          }

          if (this.hasSlugOnUrl(currentUrlSlug, 'express-checkout' ) >= 0 ) {
            this.fetchMessages = false;
            console.log( 'this.fetchMessages 2', this.fetchMessages )
            this.setClassesForExpresCheckout();
            return;
          }

          if ( this.hasSlugOnUrl( currentUrlSlug, 'recovery-password'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'create-password'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'sign-up'  ) >= 0
              || this.hasSlugOnUrl( currentUrlSlug, 'signup'  ) >= 0 ) {
            this.setClassesForLogin();
          }

          if ( this.hasSlugOnUrl( currentUrlSlug, 'check/'  ) >= 0
            || this.hasSlugOnUrl( currentUrlSlug, 'invoice/'  ) >= 0
            || this.hasSlugOnUrl( currentUrlSlug, 'checkout/'  ) >= 0
            || this.hasSlugOnUrl( currentUrlSlug, 'bank-account'  ) >= 0
            || this.hasSlugOnUrl( currentUrlSlug, 'confirm-email/'  ) >= 0 ) {
            this.setClassesForClient();
          }

          if (this.router.isActive('\login', true)
            || this.router.isActive('\sign-up', true) ) {
            this.setClassesForLogin();
          }
          if ( currentUrlSlug.indexOf('check/v2/') + 1 ) {
            this.classesForCheckV2();
          }
          if ( currentUrlSlug.indexOf('invoice/v2/') + 1 ) {
            /*this.classesForInvoiceV2();*/
            this.classesForCheckV2();
          }
        }
      });

  }

  getCurentSlug( url: string = '' ) {
    const currentUrl = url.slice(1);
    const arrUrlSlug = currentUrl.split('?');
    return arrUrlSlug[0];
  }

  hasSlugOnUrl(strSlug: string, strPattern: string ) {
    if ( strSlug == 'rules-for-signup' ) {
      return -1;
    }
    return strSlug.indexOf(strPattern );
  }

  ngOnInit() {
    this.initFingerPrint();
    setTimeout( () => {
      console.log( 'this.fetchMessages 3', this.fetchMessages )
      if (this.fetchMessages ) {
        this.messages.init();
      }
    }, 300 )
    

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });

  }

  async initFingerPrint(): Promise<void> {
    const data = await this.fingerprintService.getVisitorData()
    // console.log( data )
    // console.log( data.visitorId )
    localStorage.setItem( "FPB", data.visitorId );
    localStorage.setItem( "FPB_request_id", data.requestId );
    localStorage.setItem( "FPB_extended", JSON.stringify( data ) );
  }

  setClassesForClient() {
    this.renderer.addClass(document.body, 'client-wrapper');
  }

  setClassesForLogin() {
    this.renderer.addClass(document.body, 'auth-wrapper');
    this.renderer.removeClass(document.body, 'menu-position-side');
    this.renderer.removeClass(document.body, 'menu-side-left');
    // this.renderer.removeClass(document.body, 'full-screen');
    this.renderer.removeClass(document.body, 'with-content-panel');
    this.renderer.removeClass(document.body, 'sand-box-mode');
    this.renderer.removeClass(document.body, 'demo-box-mode');
    this.renderer.removeClass(document.body, 'color-scheme-dark');
  }

  classesForCheckV2() {
    this.renderer.removeClass(document.body, 'client-wrapper');
    this.renderer.addClass(document.body, 'check-v2-wrapper');
  }
  classesForInvoiceV2() {
    this.renderer.removeClass(document.body, 'client-wrapper');
    this.renderer.addClass(document.body, 'invoice-v2-wrapper');
  }

  setClassesForLoginedUser() {
    this.renderer.removeClass(document.body, 'auth-wrapper');
    this.renderer.addClass(document.body, 'menu-position-side');
    this.renderer.addClass(document.body, 'menu-side-left');
    this.renderer.removeClass(document.body, 'client-wrapper');
    // this.renderer.addClass(document.body, 'full-screen');
    this.renderer.addClass(document.body, 'with-content-panel');
    if (this.userService.isSandBoxMode() && !this.userService.isDemoUser() ) {
      this.renderer.addClass(document.body, 'sand-box-mode');
    }
    if (this.userService.isSandBoxMode() && this.userService.isDemoUser() ) {
      this.renderer.addClass(document.body, 'demo-box-mode');
    }
    if ( localStorage.getItem('colorScheme') ) {
      this.renderer.addClass(document.body, localStorage.getItem('colorScheme'));
    }
  }

  setClassesForExpresCheckout() {
    this.renderer.addClass(document.body, 'expres-checkout-wrapper');
    this.renderer.removeClass(document.body, 'menu-position-side');
    this.renderer.removeClass(document.body, 'menu-side-left');
    // this.renderer.removeClass(document.body, 'full-screen');
    this.renderer.removeClass(document.body, 'with-content-panel');
    this.renderer.removeClass(document.body, 'sand-box-mode');
    this.renderer.removeClass(document.body, 'demo-box-mode');
    this.renderer.removeClass(document.body, 'client-wrapper');
    this.renderer.removeClass(document.body, 'auth-wrapper');

    // this.renderer.removeClass(document.body, 'color-scheme-dark' );
  }

  parseGoogleCampaingSource( url: string = '' ) {
    const objUrlTree = this.urlSerialise.parse( url);
    if ( objUrlTree.queryParamMap.keys.length ) {
      localStorage.setItem('google_campaign', JSON.stringify(objUrlTree.queryParams));
    }
  }
}
