import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ErrorService } from "../../_services/error.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../../auth.service";
import { Utility } from "../../_helpers/utility";
import { Router } from "@angular/router";
import { BankRoutingService } from "../../_services/bank-routing.service";
import { UserService } from "../../_services/user.service";
import { MessagesService } from "../../_services/messages.service";
import { Spinkit } from "ng-http-loader";
import { JqueryService } from "../../_services/jquery.service";
import { TopAlertsService } from "../../_services/top-alerts.service";
import { validatePhone } from "../../_vaidators/phone";
import {PlaidClientService} from '../../_services/plaid-client.service';
import {PlaidLinkHandler} from 'ngx-plaid-link';
import { TimerService } from '../../_services/timer.service';

// declare var Plaid: any;

@Component({
  selector: "app-invoice-from-customers",
  templateUrl: "./invoice-from-customers.component.html",
  styleUrls: ["./invoice-from-customers.component.css"],
})
export class InvoiceFromCustomersComponent implements OnInit {
  public spinkit = Spinkit;
  public host: string = environment.host;
  public isValidPayLink = true;
  public modalRef: NgbModalRef;
  public objDigitCheck: any = {
    verifyCodeSend: false,
    bankAccountType: "checking",
    acceptPolicy: false,
    accountType: "",
    account_id: 0,
    recurring: false,
    billing_cycle: "month",
  };
  public lstAccounts: any = [];
  public objCurAccount: any = {};
  public objBankInfo: any = {};
  public objFutureUser: any = {};
  public objExistUser: any = {};
  public isLoading = false;
  public isInvoiceValid = false;
  public isInvoicePending = false;
  public paddingTop = 10;
  public isInvoiceProcessed = false;
  public isInvoiceCanseled = false;
  public isInvoiceHold = false;
  public isExistUser = false;
  public needOnDemandBankAccount = false;
  public isManualVerificationExist = false;
  public thankyouMessage = "";
  public backButtonContent: any;
  public subDomen: string = environment.subDomen;
  public intErrorSeconds = 60000;
  public objLink: any = environment.link;

  public objInvoice: any = {};

  public verifyPhoneFormGroup: FormGroup;

  public objInFromCustComp: any = {
    isInvoice: <boolean>false,
    isPayLink: <boolean>false,
    isPayLinkValid: <boolean>false,
    isPayLinkPlaidVerified: <boolean>false,
    isPayLinkManualMustVerify: <boolean>false,
    isPayLinkSuccessSendMoney: <boolean>false,
    objPaymentLink: <any>{},
    objPaymentLinkForm: <any>{},
    rulesForm: {
      minSendAmount: 2,
      disableInputSendAmount: false,
    },
    objInvoiceExperience: <any>null,
  };

  @ViewChild('depositCheckOnline')
  private depositCheckOnline: TemplateRef<any>;

  @ViewChild("verifyPhone", { static: true })
  private verifyPhone: TemplateRef<any>;

  @ViewChild("verifyPhoneExistUser", { static: true })
  private verifyPhoneExistUser: TemplateRef<any>;

  @ViewChild("selectTypeAddFundingSource", { static: true })
  private selectTypeAddFundingSource: TemplateRef<any>;
  private phoneMask: any;
  public objPaymentLink: any;

  constructor(
    private http: HttpClient,
    public errorService: ErrorService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private router: ActivatedRoute,
    public utility: Utility,
    private bankRoutingService: BankRoutingService,
    private _formBuilder: FormBuilder,
    private route: Router,
    public userService: UserService,
    public messages: MessagesService,
    public topAlertsService: TopAlertsService,
    public jqueryService: JqueryService,
    public plaidService: PlaidClientService,
    public timerService: TimerService,
  ) {}

  ngOnInit() {
    this.timerService.initTimerCD("down");
    //this.userService.setFingerPrintBrowser();
    if (this.router.snapshot.data["isSandbox"]) {
      this.host = environment.sandboxHost;
    }

    this.verifyPhoneFormGroup = this._formBuilder.group({
      phone_pin: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
      phone: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14),
          validatePhone,
        ]),
      ],
      pin_part_1: ["", Validators.required],
      pin_part_2: ["", Validators.required],
      pin_part_3: ["", Validators.required],
      pin_part_4: ["", Validators.required],
      pin_part_5: ["", Validators.required],
      pin_part_6: ["", Validators.required],
    });
    this.authenticationService.clearLogin();
    this.initInvoice();
  }

  isAvailablePhoneVerification() {
    return environment.availablePhoneVerification;
  }

  moveToNextStepAfterVerify(flag: boolean) {
    if (flag) {
      if (
        this.objCurAccount.status == "unverified" ||
        !this.lstAccounts.length ||
        this.needOnDemandBankAccount
      ) {
        this.openModal(this.selectTypeAddFundingSource);
      }
      if (
        this.objCurAccount.status == "verified" &&
        !this.needOnDemandBankAccount
      ) {
        this.openModal(this.depositCheckOnline);
      }
    }
  }
  moveToPreviousStep(flag: boolean) {
    if (!this.isExistUser) {
      this.phoneMask = "";
    }

    flag ? this.openBackModal(this.depositCheckOnline) : this.closeModal();
  }

  pinCodePhone(phoneAndPincode: any) {
    this.verifyPhoneFormGroup.reset({
      phone_pin: phoneAndPincode.phonepincode,
      phone: phoneAndPincode.phone,
    });
  }

  getRecName() {
    return  this.objInvoice.rec_name.replace(/&amp;/g, '&')
  }

  initInvoice() {
    if (this.router.snapshot.paramMap.get("invoice_token")) {
      this.objInFromCustComp.isInvoice = true;
      const invoice_token = this.router.snapshot.paramMap.get("invoice_token");
      this.verifyInvoiceLInk(invoice_token);
    }
    if (this.router.snapshot.paramMap.get("checkout_token")) {
      this.objInFromCustComp.isPayLink = true;
      const checkout_token =
        this.router.snapshot.paramMap.get("checkout_token");
      this.verifyPaymentLink(checkout_token);
    }
  }

  nextDigit(intStep: number = 0) {
    switch (intStep) {
      case 1:
        if (
          this.verifyPhoneFormGroup.value.pin_part_1 != "" &&
          this.verifyPhoneFormGroup.value.pin_part_1 >= 0
        ) {
          this.jqueryService.setFocus("#pin_part_" + (intStep + 1));
        }
        break;
      case 2:
        if (
          this.verifyPhoneFormGroup.value.pin_part_2 != "" &&
          this.verifyPhoneFormGroup.value.pin_part_2 >= 0
        ) {
          this.jqueryService.setFocus("#pin_part_" + (intStep + 1));
        }
        break;
      case 3:
        if (
          this.verifyPhoneFormGroup.value.pin_part_3 != "" &&
          this.verifyPhoneFormGroup.value.pin_part_3 >= 0
        ) {
          this.jqueryService.setFocus("#pin_part_" + (intStep + 1));
        }
        break;
      case 4:
        if (
          this.verifyPhoneFormGroup.value.pin_part_4 != "" &&
          this.verifyPhoneFormGroup.value.pin_part_4 >= 0
        ) {
          this.jqueryService.setFocus("#pin_part_" + (intStep + 1));
        }
        break;
      case 5:
        if (
          this.verifyPhoneFormGroup.value.pin_part_5 != "" &&
          this.verifyPhoneFormGroup.value.pin_part_5 >= 0
        ) {
          this.jqueryService.setFocus("#pin_part_" + (intStep + 1));
        }
        break;
    }
  }

  onAccept(modalContent: any = null, backModalContent: any = null) {
    this.openModal(modalContent, backModalContent);
    if (this.isAvailablePhoneVerification()) {
      setTimeout(() => this.jqueryService.setFocus("#phone"), 200);
    } else {
      this.sendVerifyCode();
    }
  }
  getPaddingTop(){
    return this.paddingTop + '%';
  }

  verifyPaymentLink(checkout_token: string) {
    this.http
      .get<any>(this.host + "/customer/billing-link/retrive", {
        params: { bl_token: checkout_token },
      })
      .subscribe(
        (response) => {
          if (response.success) {
            console.log('objPaymentLink', response);
            this.objInFromCustComp.isPayLinkValid = <boolean>(
              response.link.enabled
            );
            this.objInFromCustComp.objPaymentLink = response;
            this.paddingTop = 10 + (!(this.objInFromCustComp.objPaymentLink.link.amount > 0 || this.objInFromCustComp.objPaymentLink.link.description) ? 0 : 10) + (this.objInFromCustComp.objPaymentLink.link.recurring ? 0 : 10);
            this.objInFromCustComp.objPaymentLinkForm.billing_cycle = this.objInFromCustComp.objPaymentLink.link.billing_cycle;
            this.objInFromCustComp.objPaymentLinkForm.recurring = this.objInFromCustComp.objPaymentLink.link.recurring;
            this.objInFromCustComp.objPaymentLinkForm.num_of_payments = this.objInFromCustComp.objPaymentLink.link.num_of_payments;
            this.objInFromCustComp.objPaymentLinkForm.amount = this.objInFromCustComp.objPaymentLink.link.amount > 0
              ? this.objInFromCustComp.objPaymentLink.link.amount : '';
            this.objInFromCustComp.objPaymentLinkForm.description = this.objInFromCustComp.objPaymentLink.link.description;
            this.objInFromCustComp.rulesForm.disableInputSendAmount = this.objInFromCustComp.objPaymentLink.link.amount > 0;

            this.objPaymentLink = {thirdPartyBankVerification: response.thirdPartyBankVerification};
          }
        },
        (err) => {
          if (err.error) {
            this.isInvoiceValid = false;
            this.utility.getMessageError(err.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  getCssClassForInstantVerification() {
    if (this.objInFromCustComp.isPayLink) {
      return !this.objInFromCustComp.objPaymentLink.link.is_manual_v
        ? "col-sm-12"
        : "col-sm-6";
    }

    if (this.objInFromCustComp.isInvoice) {
      return !this.haveManualVerification() ? "col-sm-12" : "col-sm-6";
    }
  }

  canShowManualVerification() {
    if (this.objInFromCustComp.isPayLink) {
      return !!(
        this.objInFromCustComp.isPayLink &&
        this.objInFromCustComp.objPaymentLink.link.is_manual_v
      );
    }

    if (this.objInFromCustComp.isInvoice) {
      return this.haveManualVerification();
    }
  }

  submitBillLink() {
    this.isLoading = true;
    //this.userService.setFingerPrintBrowser();
    let objRequest = {
      bl_token: this.objInFromCustComp.objPaymentLink.link.bl_token,
      fpb: this.authenticationService.getFingerPrintBrowser(),
      fpb_request_id: this.authenticationService.getFingerPrintBrowserRequestId(),
      browser: this.authenticationService.browComponent,
    };

    objRequest = Object.assign(
      objRequest,
      this.objInFromCustComp.objPaymentLinkForm
    );

    this.http
      .post<any>(this.host + "/customer/check/invoice/billing-link", objRequest)
      .subscribe(
        (response) => {
          if (response.success) {
            this.objPaymentLink = response.invoice;
            this.objPaymentLink.thirdPartyBankVerification = response.thirdPartyBankVerification;
            this.isLoading = false;
            this.initPrepareInvoice(response);
            if ( this.isExistUser ) {
              if ( this.requireToFAuth() ) {
                this.sendVerifyCode();
                this.openModal(this.verifyPhoneExistUser);
                /*this.sendVerifyCode(false);*/
              } else {
                this.openModal(this.depositCheckOnline);
              }
            } else {
              this.openModal(this.depositCheckOnline);
            }
            //this.userService.setFingerPrintBrowser();
          }
        },
        (err) => {
          this.isLoading = false;
          if (err.error) {
            this.utility.getMessageError(err.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  getRedirectUrl() {
    return this.objInFromCustComp.objPaymentLink.link.callback
      ? this.objInFromCustComp.objPaymentLink.link.callback
      : "https://paynote.io/";
  }

  verifyInvoiceLInk(invoice_token: string) {
    this.errorService.clearAlerts();
    const objRequest = {
      i_token: invoice_token,
    };
    this.http
      .get<any>(this.host + "/invoice/customer/retrieve", {
        params: objRequest,
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.objPaymentLink = response.invoice;
            this.objPaymentLink.thirdPartyBankVerification = response.thirdPartyBankVerification;
            this.initPrepareInvoice(response);
            this.utility.debugValue(response, "Invoice Object");
          }
        },
        (err) => {
          if (err.error) {
            this.isInvoiceValid = false;
            this.errorService.getMessageError(err.error);
          }
        }
      );
  }

  initPrepareInvoice(response: any = null) {
    this.lstAccounts = [];
    this.objCurAccount = {};
    this.objExistUser = {};
    this.isExistUser = false;

    this.objInvoice = response.invoice;
    if (response.doing_business_as && response.doing_business_as != "") {
      this.objInvoice.sndr_name = response.doing_business_as;
    }
    this.objDigitCheck.accountName = this.objInvoice.sndr_name;
    this.setFirstLastName();

    this.objInFromCustComp.objInvoiceExperience =
      response.invoice_experience && this.objInFromCustComp.isInvoice
        ? response.invoice_experience
        : null;
    if (response.user) {
      this.isExistUser = true;
      if (
        !response.isOnDemandBankAccount &&
        this.objInFromCustComp.objPaymentLink.on_demand_auth
      ) {
        this.needOnDemandBankAccount = true;
      }
      this.objExistUser = response.user;
      this.phoneMask = response.masked_phone;
      this.objDigitCheck.accountType = this.objExistUser.type;
    }

    if (response.accounts && response.accounts.length) {
      this.lstAccounts = response.accounts;
      this.objCurAccount = response.accounts[0];
    }
    if (response.internal_account) {
      const internal_account = response.internal_account;
      internal_account.balance = response.internal_balance;
      this.lstAccounts.push(internal_account);
    }
    if (
      response.internal_account &&
      response.internal_account.dwl_fs_token &&
      this.isTransferFromBalance()
    ) {
      this.objCurAccount = this.lstAccounts[this.lstAccounts.length - 1];
      this.objDigitCheck.account_id = this.lstAccounts.length - 1;
    }

    if (
      this.objInvoice.billing_cycle &&
      this.objInvoice.billing_cycle != "null"
    ) {
      this.objDigitCheck.billing_cycle = this.objInvoice.billing_cycle;
      this.objDigitCheck.recurring = true;
      this.objDigitCheck.num_of_payments = this.objInvoice.num_of_payments;
    }
    this.isInvoiceValid = true;
    this.thankyouMessage =
      "Thank you! Your payment was sent to " +
      this.objInvoice.rec_name +
      " using SeamlessChex!";
    if (
      this.objInvoice.status.toLowerCase() == "pending" ||
      this.objInvoice.status.toLowerCase() == "new"
    ) {
      this.isInvoicePending = true;
    }
    if (
      this.objInvoice.status.toLowerCase() == "processing" ||
      this.objInvoice.status.toLowerCase() == "pending" ||
      this.objInvoice.status.toLowerCase() == "processed"
    ) {
      this.isInvoiceProcessed = true;
      this.isInvoiceValid = false;
    }
    if (
      this.objInvoice.status.toLowerCase() == "canceled" ||
      this.objInvoice.status.toLowerCase() == "cancelled" ||
      this.objInvoice.status.toLowerCase() == "void pending" ||
      this.objInvoice.status.toLowerCase() == "cancel pending" ||
      this.objInvoice.status.toLowerCase() == "voided"
    ) {
      this.isInvoiceCanseled = true;
      this.isInvoiceValid = false;
    }
    if (
      this.objInvoice.status.toLowerCase() == "hold" ||
      this.objInvoice.status.toLowerCase() == "declined"
    ) {
      this.isInvoiceHold = true;
      this.isInvoiceValid = false;
    }
  }

  sendInvoiceCheck(content: any, contentBack: any = null) {
    this.errorService.clearAlerts();
    this.isLoading = true;
    //this.userService.setFingerPrintBrowser();

    const objRequest = {
      i_token: this.objInvoice.i_token,
      fs_token: this.objCurAccount.dwl_fs_token,
      phone_pin: this.verifyPhoneFormGroup.value.phone_pin,
      name: this.objExistUser.name,
      email: this.objExistUser.email,
      acceptPolicy: true,
      billing_cycle: this.objDigitCheck.billing_cycle,
      recurring: this.objDigitCheck.recurring,
      num_of_payments: this.objDigitCheck.num_of_payments,
      fpb: this.authenticationService.getFingerPrintBrowser(),
      fpb_request_id: this.authenticationService.getFingerPrintBrowserRequestId(),
      browser: this.authenticationService.browComponent,
      capture_auth: this.objInFromCustComp.objPaymentLink.on_demand_auth,
      need_on_demand_ba: this.needOnDemandBankAccount,
    };
    this.http
      .post<any>(
        this.host + "/check/invoice/payment/create/manual-verification",
        JSON.stringify(objRequest)
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.isLoading = false;
            this.objFutureUser = response;
            //this.userService.setFingerPrintBrowser();

            if (this.objInFromCustComp.isPayLink) {
              this.objInFromCustComp.isPayLinkSuccessSendMoney = true;
              this.closeModal();
            } else {
              this.isInvoiceProcessed = true;
              this.isInvoiceValid = false;
              this.isInvoicePending = false;
              this.openModal(content, contentBack);
            }
          }
        },
        (err) => {
          this.isLoading = false;
          if (err.error) {
            this.errorService.getMessageError(err.error, this.intErrorSeconds);
          }
        }
      );
  }

  setThankYouMessage(bRedirect: boolean = false, url: string = "") {
    this.closeModal();
    localStorage.setItem("thankyou_page_message", this.thankyouMessage);
    if (bRedirect) {
      window.location.href = url;
    }
  }

  selectAnotherBankAccount() {
    this.objCurAccount = this.lstAccounts[this.objDigitCheck.account_id];
  }

  getDateFormat(strDate: string) {
    return this.utility.getDateFormat(strDate);
  }

  openModal(content: any, contentBack: any = null) {
    if(content == this.depositCheckOnline && !this.isExistUser){
      this.objDigitCheck.accountType = '';
      this.objDigitCheck.acceptPolicy = false;
    }
    if(content == this.verifyPhone || content == this.verifyPhoneExistUser){
      this.clearVirifyCode();
    }
    if (!this.isExistUser) {
      this.phoneMask = "";
    }

    this.errorService.clearAlerts();
    this.closeModal();
    if (contentBack) {
      this.backButtonContent = contentBack;
    }
    this.modalRef = this.modalService.open(content, { backdrop: "static" });
  }

  openBackModal(content: any = null) {
    this.errorService.clearAlerts();
    this.closeModal();
    if (this.backButtonContent) {
      this.modalRef = this.modalService.open(this.backButtonContent, {
        backdrop: "static",
      });
      this.backButtonContent = null;
    } else if (content) {
      content == this.verifyPhone && !this.requireToFAuth()
        ? (this.modalRef = this.modalService.open(this.depositCheckOnline, {
            backdrop: "static",
          }))
        : (this.modalRef = this.modalService.open(content, {
            backdrop: "static",
          }));
    }
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  getLastDigitAccount() {
    if (this.objDigitCheck.mask) {
      return this.objDigitCheck.mask;
    }

    if (!this.objDigitCheck.accountNumber) {
      return false;
    }

    return this.objDigitCheck.accountNumber.substr(
      this.objDigitCheck.accountNumber.length - 4
    );
  }

  initRedirect() {
    setTimeout(() => {
      if (this.getRedirectUrl().indexOf("http") >= 0) {
        window.location.href = this.getRedirectUrl();
      } else {
        window.location.href = "https://" + this.getRedirectUrl();
      }
    }, 5000);
  }

  onPlaidSuccess(event) {
    const objAccountInfo = event.metadata;
    const publicToken = event.token;
    const vm = this;
    vm.objDigitCheck.mask = objAccountInfo.account.mask;
    vm.objDigitCheck.bank_name = objAccountInfo.institution.name;
    vm.isLoading = true;
    vm.http
      .post<any>(
        vm.host + "/check/invoice/payment/create/instant-verification",
        {
          account_id: objAccountInfo.account_id,
          public_token: publicToken,
          i_token: vm.objInvoice.i_token,
          email: vm.objInvoice.sndr_email,
          atype: vm.objDigitCheck.accountType,
          phone: vm.utility.clearPhoneNumber(
            vm.verifyPhoneFormGroup.value.phone
          ),
          phone_pin: vm.verifyPhoneFormGroup.value.phone_pin,
          acceptPolicy: vm.objDigitCheck.acceptPolicy || vm.isExistUser,
          name:
            vm.objDigitCheck.firstName + " " + vm.objDigitCheck.lastName,
          fpb: vm.authenticationService.getFingerPrintBrowser(),
          fpb_request_id: this.authenticationService.getFingerPrintBrowserRequestId(),
          browser: vm.authenticationService.browComponent,
          ipAddress: localStorage.getItem("ipClient") || null,
          billing_cycle: vm.objDigitCheck.billing_cycle,
          recurring: vm.objDigitCheck.recurring,
          num_of_payments: vm.objDigitCheck.num_of_payments,
          capture_auth:
          vm.objInFromCustComp.objPaymentLink.on_demand_auth,
          need_on_demand_ba: vm.needOnDemandBankAccount,
        }
      )
      .subscribe(
        (response) => {
          if (response.success) {
            vm.isLoading = false;
            vm.objFutureUser = response;
            if (vm.objInFromCustComp.isPayLink) {
              vm.closeModal();
              vm.objInFromCustComp.isPayLinkPlaidVerified = true;
              vm.initRedirect();
            } else {
              vm.isInvoiceValid = false;
              vm.isValidPayLink = false;
              vm.isInvoiceProcessed = true;
              vm.openModal(event.modalContent);
            }
            //vm.userService.setFingerPrintBrowser();
          }
        },
        (errResponse) => {
          vm.isLoading = false;
          if (errResponse.error) {
            if (event.backContent) {
              vm.openModal(event.backContent);
            }
            vm.errorService.getMessageError(
              errResponse.error,
              vm.intErrorSeconds
            );
          }
        }
      );
  }

  onPlaidExit(event) {
    this.isLoading = false;
  }

  openPlaidModal(modalContent?, backContent?) {
    this.isLoading = true;
    this.plaidService.init(this, { i_token: this.objInvoice.i_token }, modalContent, backContent)
      .then((plaidHandler: PlaidLinkHandler) => {
        this.isLoading = false;
        plaidHandler.open();
      });
  }

  collectVerifyCode() {
    this.verifyPhoneFormGroup.patchValue({
      phone_pin:
        this.verifyPhoneFormGroup.value.pin_part_1 +
        this.verifyPhoneFormGroup.value.pin_part_2 +
        this.verifyPhoneFormGroup.value.pin_part_3 +
        this.verifyPhoneFormGroup.value.pin_part_4 +
        this.verifyPhoneFormGroup.value.pin_part_5 +
        this.verifyPhoneFormGroup.value.pin_part_6,
    });
  }

  clearVirifyCode() {
    this.verifyPhoneFormGroup.patchValue({
      pin_part_1: "",
      pin_part_2: "",
      pin_part_3: "",
      pin_part_4: "",
      pin_part_5: "",
      pin_part_6: "",
      phone_pin: "",
    });
    this.jqueryService.setFocus("#pin_part_1");
  }

  verifyPhoneCode(
    modalContent: any,
    bIsForExistUser: boolean = false,
    contentBank: any = null
  ) {
    this.errorService.clearAlerts();
    this.collectVerifyCode();
    if (bIsForExistUser) {
      this.authenticationService
        .verifyPhoneCodeForAuthUser(
          this.objInvoice.sndr_token,
          this.verifyPhoneFormGroup.value.phone_pin
        )
        .subscribe(
          (result) => {
            const objResp = <any>result;
            if (objResp.success) {
              this.openModal(modalContent, contentBank);
            }
          },
          (err) => {
            if (err.error) {
              this.errorService.getMessageError(err.error);
            }
          }
        );
    } else {
      this.authenticationService
        .verifyEmailCode(
          this.objInvoice.sndr_email,
          this.verifyPhoneFormGroup.value.phone_pin
        )
        .subscribe(
          (result) => {
            const objResp = <any>result;
            if (objResp.success) {
              this.openModal(modalContent, contentBank);
            }
          },
          (err) => {
            if (err.error) {
              this.errorService.getMessageError(err.error);
            }
          }
        );
    }
  }

  sendVerifyCode(bIsForExistUser: boolean = false) {
    if (this.timerService.isTimerRun) {
      return;
    }
    if (this.isAvailablePhoneVerification()) {
      return;
    }
    this.errorService.clearAlerts();
    this.objDigitCheck.verifyCodeSend = true;
    if (bIsForExistUser ) {
      this.verifyPhoneFormGroup.reset();
      this.authenticationService
        .sendVerifyPhoneCodeForAuthUser(this.objInvoice.sndr_token)
        .subscribe(
          (result) => {
            const objResp = <any>result;
            if (objResp.success) {
              this.timerService.setTime(60, "s");
              this.errorService.getMessageSuccess(objResp);
            }
          },
          (err) => {
            if (err.error.error) {
              this.errorService.getMessageError(err.error);
            }
          }
        );
    } else {
      this.authenticationService
        .sendVerifyCodeToEmail(this.objInvoice.sndr_email)
        .subscribe(
          (result) => {
            const objResp = <any>result;
            if (objResp.success) {
              this.timerService.setTime(60, "s");
              this.errorService.getMessageSuccess(objResp);
            }
          },
          (err) => {
            if (err.error.error) {
              this.errorService.getMessageError(err.error);
            }
          }
        );
    }
  }

  getBankRouting() {
    this.bankRoutingService
      .getBankInfo(this.objDigitCheck.routingNumber)
      .subscribe(
        (response) => {
          if (response.success) {
            this.objBankInfo = response.bankInfo;
            this.objDigitCheck.bank_name = response.bankInfo.name;
            this.objDigitCheck.success = response.success;
            this.objDigitCheck.error = false;
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.objDigitCheck.bank_name = errResponse.error.message;
            this.objDigitCheck.error = errResponse.error.error;
            this.objDigitCheck.success = false;
          }
        }
      );
  }

  setFirstLastName() {
    const arrNames = this.objInvoice.sndr_name.split(" ");
    this.objDigitCheck.firstName = arrNames[0] ? arrNames[0] : "";
    this.objDigitCheck.lastName = arrNames[1] ? arrNames.slice(1).join(" ") : "";
  }

  hasLastName() {
    const arrNames = this.objInvoice.sndr_name.split(" ");
    if (arrNames[1]) {
      return true;
    }
    return false;
  }

  clearManualAddBankAccount() {
    this.isManualVerificationExist = false;
    this.objDigitCheck.bank_name = "";
    this.objDigitCheck.routingNumber = "";
    this.objDigitCheck.accountNumber = "";
    this.objDigitCheck.cAccountNumber = "";
  }

  signUpByInvoiceLink(content: any, contentBack: any = null) {
    this.isLoading = true;
    this.errorService.clearAlerts();
    if (this.objDigitCheck.accountNumber != this.objDigitCheck.cAccountNumber) {
      this.errorService.getMessageError({
        message: this.messages.get(
          "ACCOUNT_NUMBER_DOES_NOT_MATCH_THE_CONFIRM_ACCOUNT_NUMBER"
        ),
      });
      this.isLoading = false;
      return;
    }
    this.isManualVerificationExist = false;

    //this.userService.setFingerPrintBrowser();

    const objRequestSignUp = this.objDigitCheck;
    objRequestSignUp.phone = this.utility.clearPhoneNumber(
      this.verifyPhoneFormGroup.value.phone
    );
    objRequestSignUp.phone_pin = this.verifyPhoneFormGroup.value.phone_pin;
    objRequestSignUp.i_token = this.objInvoice.i_token;
    objRequestSignUp.email = this.objInvoice.sndr_email;
    objRequestSignUp.atype = this.objDigitCheck.accountType;
    objRequestSignUp.name =
      objRequestSignUp.firstName + " " + objRequestSignUp.lastName;
    objRequestSignUp.ipAddress = localStorage.getItem("ipClient") || null;
    objRequestSignUp.capture_auth =
      this.objInFromCustComp.objPaymentLink.on_demand_auth;
    if (this.isExistUser) {
      objRequestSignUp.acceptPolicy = true;
      //      objRequestSignUp.fs_token = this.objCurAccount.dwl_fs_token
    }

    this.authenticationService.signUpByInvoiceLink(objRequestSignUp).subscribe(
      (result) => {
        const objResp = <any>result;
        if (objResp.success) {
          this.isLoading = false;
          this.objFutureUser = objResp;
          //this.userService.setFingerPrintBrowser();
          if (this.objInFromCustComp.isPayLink) {
            this.objInFromCustComp.isPayLinkManualMustVerify = true;
            this.closeModal();
          } else {
            if (!objResp.fs_existing) {
              this.isValidPayLink = false;
              this.isInvoiceValid = false;
              this.isInvoiceProcessed = true;
              this.isInvoicePending = false;
              this.openModal(content, contentBack);
            } else {
              this.isManualVerificationExist = true;
            }
          }
        }
      },
      (err) => {
        if (err.error) {
          this.isLoading = false;
          this.errorService.getMessageError(err.error, this.intErrorSeconds);
        }
      }
    );
  }

  loginCreatedUser() {
    if (this.modalRef) {
      this.modalRef.close();
    }

    localStorage.setItem("currentUserV2", JSON.stringify(this.objFutureUser));

    this.userService.initUser();
    this.userService.retrieveUserLimits();
    this.route.navigate(["/transactions"]);
  }

  haveInstantVerification() {
    if (!this.objInFromCustComp.objInvoiceExperience) {
      return true;
    }
    return (
      this.objInFromCustComp.objInvoiceExperience &&
      (this.objInFromCustComp.objInvoiceExperience.is_instant_v ||
        this.objInFromCustComp.objInvoiceExperience.is_instant_v == 1)
    );
  }

  haveManualVerification() {
    if (!this.objInFromCustComp.objInvoiceExperience) {
      return false;
    }
    return (
      this.objInFromCustComp.objInvoiceExperience &&
      (this.objInFromCustComp.objInvoiceExperience.is_manual_v ||
        this.objInFromCustComp.objInvoiceExperience.is_manual_v == 1)
    );
  }

  haveLogo() {
    if (!this.objInFromCustComp.objInvoiceExperience) {
      return false;
    }
    return (
      this.objInFromCustComp.objInvoiceExperience.logo &&
      this.objInFromCustComp.objInvoiceExperience.logo != ""
    );
  }

  getLogo() {
    return this.objInFromCustComp.objInvoiceExperience.logo;
  }

  getButtonLabel() {
    return this.objInFromCustComp.objInvoiceExperience &&
      this.objInFromCustComp.objInvoiceExperience.button != ""
      ? this.objInFromCustComp.objInvoiceExperience.button
      : "Send Payment";
  }

  getTitle() {
    return this.objInFromCustComp.objInvoiceExperience &&
      this.objInFromCustComp.objInvoiceExperience.title != ""
      ? this.objInFromCustComp.objInvoiceExperience.title
      : "Securely send and receive digital checks online.";
  }

  requireToFAuth() {
    if (this.objInFromCustComp.isPayLink) {
      return (
        this.objInFromCustComp.objPaymentLink.link.is_two_auth ||
        this.objInFromCustComp.objPaymentLink.link.is_two_auth == 1
      );
    }
    if (this.objInFromCustComp.isInvoice) {
      if (!this.objInFromCustComp.objInvoiceExperience) {
        return true;
      }
      return (
        this.objInFromCustComp.objInvoiceExperience.is_two_auth ||
        this.objInFromCustComp.objInvoiceExperience.is_two_auth == 1
      );
    }
  }

  isTransferFromBalance() {
    if (!this.objInvoice.settings) {
      return false;
    }
    if (
      this.objInvoice.settings &&
      this.objInvoice.settings.transfer_from_balance == 1
    ) {
      return true;
    }
    return false;
  }

  protected readonly environment = environment;
}

