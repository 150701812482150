<div class="element-wrapper">
  <h6 class="element-header">Payment Requests</h6>
  <div class="element-box">
    <div class="table-responsive">
      <div class="dataTables_wrapper container-fluid dt-bootstrap4">
        <div class="row" *ngIf="!isLightVersion">
          <div class="col-sm-12 col-md-3">
            <div id="dataTable1_filter" class="dataTables_filter">
              <label>
                Search:
                <input type="search" class="form-control form-control-sm" placeholder="Search"
                       [(ngModel)]="paginationService.searchQuery" #paginationService.searchQuery="ngModel" (search)="getListInvoices()" (keyup)="getListInvoices(null)">
              </label>
            </div>
          </div>
        </div>

        <div class="row" [ngClass]="{'someStyleForTable': !isLightVersion}">
          <hr>
          <div class="col-sm-12 table-responsive">
            <table id="dataTable2" width="100%" class="table table-striped table-lightfont dataTable responsive-table" role="grid" aria-describedby="dataTable1_info" style="width: 100%;">
              <thead>
                <tr role="row">
                  <th *ngIf="!isLightVersion" scope="col">
                    <!--Name-->
                    <a href="javascript:void(0);" (click)="paginationService.applySort('number', paginationService.sortDir, getListInvoices(null))">Transaction ID</a>
                      <i *ngIf="paginationService.sortField == 'number' && !paginationService.sortIcons"class="icon-feather-chevron-down"></i>
                      <i *ngIf="paginationService.sortField == 'number' && paginationService.sortIcons" class="icon-feather-chevron-up"></i>
                  </th>
                  <th *ngIf="!isLightVersion" scope="col">
                    <a href="javascript:void(0);" (click)="paginationService.applySort('rec_name', paginationService.sortDir, getListInvoices(null))">Name</a>
                      <i *ngIf="paginationService.sortField == 'rec_name' && !paginationService.sortIcons"class="icon-feather-chevron-down"></i>
                      <i *ngIf="paginationService.sortField == 'rec_name' && paginationService.sortIcons" class="icon-feather-chevron-up"></i>
                  </th>
                  <th *ngIf="!isLightVersion" scope="col">
                    <!--Email-->
                    <a href="javascript:void(0);" (click)="paginationService.applySort('rec_email', paginationService.sortDir, getListInvoices(null))">Email</a>
                      <i *ngIf="paginationService.sortField == 'rec_email' && !paginationService.sortIcons"class="icon-feather-chevron-down"></i>
                      <i *ngIf="paginationService.sortField == 'rec_email' && paginationService.sortIcons" class="icon-feather-chevron-up"></i>
                  </th>
                  <th *ngIf="!isLightVersion" scope="col">
                    <!--Phone-->
                    <a href="javascript:void(0);" (click)="paginationService.applySort('amount', paginationService.sortDir, getListInvoices(null))">Amount</a>
                      <i *ngIf="paginationService.sortField == 'amount' && !paginationService.sortIcons"class="icon-feather-chevron-down"></i>
                      <i *ngIf="paginationService.sortField == 'amount' && paginationService.sortIcons" class="icon-feather-chevron-up"></i>
                  </th>
                  <th *ngIf="!isLightVersion" scope="col">
                    <!--Status-->
                    <a href="javascript:void(0);" (click)="paginationService.applySort('status', paginationService.sortDir, getListInvoices(null))">Status</a>
                      <i *ngIf="paginationService.sortField == 'status' && !paginationService.sortIcons"class="icon-feather-chevron-down"></i>
                      <i *ngIf="paginationService.sortField == 'status' && paginationService.sortIcons" class="icon-feather-chevron-up"></i>
                  </th>
                  <th *ngIf="!isLightVersion" scope="col">
                    <!--Created-->
                    <a href="javascript:void(0);" (click)="paginationService.applySort('created_at', paginationService.sortDir, getListInvoices(null))">Created</a>
                      <i *ngIf="paginationService.sortField == 'created_at' && !paginationService.sortIcons"class="icon-feather-chevron-down"></i>
                      <i *ngIf="paginationService.sortField == 'created_at' && paginationService.sortIcons" class="icon-feather-chevron-up"></i>
                  </th>
                  <th *ngIf="!isLightVersion" scope="col">
                    <!--Created-->
                    <a href="javascript:void(0);" (click)="paginationService.applySort('description', paginationService.sortDir, getListInvoices(null))">Description</a>
                      <i *ngIf="paginationService.sortField == 'description' && !paginationService.sortIcons"class="icon-feather-chevron-down"></i>
                      <i *ngIf="paginationService.sortField == 'description' && paginationService.sortIcons" class="icon-feather-chevron-up"></i>
                  </th>
                  <th *ngIf="isLightVersion"  scope="col">Name/Email</th>
                  <th *ngIf="isLightVersion"  scope="col">Amount</th>
                  <th *ngIf="isLightVersion"  scope="col">Created</th>
                  <th *ngIf="isLightVersion"  scope="col">Description</th>
                  <th *ngIf="permissionsService.isOnlyRead()" scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" *ngFor="let invoice of listInvoices">
                  <td *ngIf="!isLightVersion" data-title="Number" scope="row">
                    {{invoice.number || '&nbsp;'}}
                    <span class="badge badge-warning" style="margin-right: 3px;display: block;font-size: 75%;" *ngIf="invoice.is_rtp > 0">
                                            Real Time
                                        </span>
                    <span class="badge badge-warning" style="margin-right: 3px;display: block;font-size: 75%;" *ngIf="invoice.is_nextday > 0">
                                            Next Day
                                        </span>
                    <span class="badge badge-warning" style="margin-right: 3px;display: block;font-size: 75%;" *ngIf="invoice.is_same_day > 0">
                                            Same Day
                                        </span>
                    <span class="badge badge-warning" style="margin-right: 3px; display: block;font-size: 75%;" placement="top" ngbTooltip="{{ transService.getTextForDelayed( invoice ) }}" *ngIf="transService.isDelayed(invoice)">
                                            Delayed
                                        </span>
                  </td>
                  <td *ngIf="!isLightVersion" data-title="Email">
                    {{invoice.rec_name || '&nbsp;'}}
                  </td>
                  <td *ngIf="!isLightVersion" data-title="Email">
                    {{invoice.rec_email || '&nbsp;'}}
                  </td>
                  <td *ngIf="isLightVersion" data-title="Name" scope="row">
                    {{invoice.rec_name || '&nbsp;'}}<br>
                    {{invoice.rec_email || '&nbsp;'}}
                  </td>
                  <td data-title="Amount">
                    {{invoice.amount | currency:'' :'symbol' || '&nbsp;'}}
                  </td>
                  <td *ngIf="!isLightVersion" data-title="Status">
                    <strong *ngIf="invoice.status == 'New'" class="badge badge-warning">{{invoice.status || '&nbsp;'}}</strong>
                    <span *ngIf="invoice.status != 'New'">{{invoice.status || '&nbsp;'}}</span>
                  </td>
                  <td data-title="Created">
                    {{userService.getDateFormat(invoice.created_at)}}
                  </td>
                  <td data-title="Description">
                    {{invoice.description || '&nbsp;'}}
                  </td>
                  <td *ngIf="permissionsService.isOnlyRead()" data-title="Action">
                    <a style="margin: 3px;padding: 5px 13px;" class="btn btn-primary btn-sm btn-rounded"
                        href="#/invoice/{{invoice.ci_token}}" placement="top" ngbTooltip="Pay Request Payment" target="_blank"
                       *ngIf="invoice.status == 'unpaid'">
                      <i class="fa fa-dollar" aria-hidden="true"></i> Pay
                    </a>
                    <a href="javascript:void(0);"  data-title="Action" class="btn btn-danger btn-sm btn-rounded"
                       placement="top" ngbTooltip="Decline" style="margin: 3px;padding: 5px 13px;"
                       *ngIf="canDecline(invoice)" (click)="voidTransaction(invoice)">
                      <i class="fa fa-trash-o"></i> Decline
                    </a>                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="!paginationService.visiblePagination" class="ssg-nothing-found">
          <div class="icon-w">
            <i class="os-icon os-icon-eye-off"></i>
          </div>
          <span>No data were found.</span>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12">
              <div *ngIf="paginationService.visiblePagination && !isLightVersion" class="dataTables_info" id="dataTable1_info" role="status" aria-live="polite">
                <div class="showentries">Show entries</div>
                <ng-pagination
                  [position]="'left'"
                  [pageSize]="paginationService.pageSize"
                   [itemsCount]="paginationService.itemsCount"
                  (getPageData)="getListInvoices($event)">
                </ng-pagination>
                <!---->
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
