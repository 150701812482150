<ng-progress color="purple" [thick]="true"></ng-progress>
<div class="all-wrapper menu-side with-pattern">
  <div class="auth-box-w">
    <div class="logo-w">
      <a routerLink="/"
        ><img alt="" src="assets/img/Paynote_-_New_Blue.svg"
      /></a>
    </div>
    <div *ngIf="isFirstStep">
      <h4 class="auth-header">Create Password</h4>
      <form
        name="form2FA"
        (ngSubmit)="fResetPas.form.valid && createPassword()"
        #fResetPas="ngForm"
        autocomplete="off"
      >
        <label
          style="font-size: 12px"
          [innerHTML]="messages.get('CREATE_PASSWORD_PAGE_MESSAGE')"
        ></label>
        <br />
        <div
          [hidden]="!errorService.isSuccess"
          class="alert alert-success"
          role="alert"
          [innerHTML]="errorService.successMessage"
        ></div>
        <div
          class="form-group"
          [ngClass]="{
            'has-error has-danger':
              !emailPassword.valid &&
              (emailPassword.dirty || emailPassword.touched)
          }"
        >
          <label>Email</label>
          <input
            name="emailPassword"
            class="form-control"
            placeholder="Please enter email"
            type="email"
            [(ngModel)]="model.emailPassword"
            #emailPassword="ngModel"
            required
          />
          <div class="pre-icon os-icon os-icon-email-2-at top-33"></div>
          <div
            *ngIf="
              !emailPassword.valid &&
              (emailPassword.dirty || emailPassword.touched)
            "
            class="help-block form-text with-errors form-control-feedback"
          >
            Email is required
          </div>
        </div>
        <div class="buttons-w">
          <button
            [disabled]="loading || !fResetPas.form.valid"
            class="btn btn-primary btn-rounded btn-rounded-margin"
          >
            Create Password
          </button>
          <br /><br />
          <a class="strong" routerLink="/login">Login</a>
          <a class="strong pull-right" routerLink="/reset-password"
            >Reset Password</a
          >
        </div>
        <br />
        <div
          [hidden]="!errorService.isError"
          class="alert alert-danger"
          role="alert"
          [innerHTML]="errorService.errorMessage"
        ></div>
      </form>
    </div>
    <div *ngIf="isSecondStep">
      <h4 class="auth-header" style="margin-bottom: 0px">Secure you Account</h4>
      <form
        name="form"
        autocomplete="test"
        (ngSubmit)="f.form.valid && setupPasswordPhoneRegister()"
        #f="ngForm"
        class="form-validation"
      >
        <label
          style="font-size: 12px; margin-bottom: 10px"
          [innerHTML]="messages.get('CREATE_PASSWORD_SECURE_PAGE_MESSAGE')"
        ></label>
        <h6 style="font-size: 14px">Password Requirements:</h6>
        <ul style="font-size: 13px">
          <li>Have at least 8 characters</li>
          <li>Have at least one lower case letter</li>
          <li>Have at least one capital letter</li>
          <li>Have at least one number</li>
        </ul>
        <div
          class="form-group"
          [ngClass]="{
            'has-error has-danger':
              !password.valid && (password.dirty || password.touched)
          }"
          style="margin-bottom: 0px"
        >
          <label for="">Password</label>
          <input
            name="password"
            class="form-control"
            placeholder="Password"
            type="password"
            minlength="8"
            [(ngModel)]="setupPasswordForm.password"
            #password="ngModel"
            required
            autocomplete="test"
          />
          <div class="pre-icon os-icon os-icon-fingerprint"></div>
          <div
            *ngIf="!password.valid && (password.dirty || password.touched)"
            class="help-block form-text with-errors form-control-feedback"
          >
            Password is required
          </div>
        </div>
        <ng9-password-strength-bar
          [passwordToCheck]="setupPasswordForm.password"
          [barLabel]="barLabel"
          [barColors]="myColors"
        >
        </ng9-password-strength-bar>
        <div
          class="form-group"
          [ngClass]="{
            'has-error has-danger':
              !cpassword.valid && (cpassword.dirty || cpassword.touched)
          }"
          style="margin-bottom: 10px"
        >
          <label for="">Confirm Password</label>
          <input
            name="cpassword"
            class="form-control"
            placeholder="Confirm Password"
            type="password"
            [(ngModel)]="setupPasswordForm.cpassword"
            #cpassword="ngModel"
            required
            autocomplete="off"
            validateEqual="password"
          />
          <div class="pre-icon os-icon os-icon-fingerprint"></div>
          <div
            *ngIf="!cpassword.valid && (cpassword.dirty || cpassword.touched)"
            class="help-block form-text with-errors form-control-feedback"
          >
            Password mismatch
          </div>
        </div>

        <div
          *ngIf="!isPhoneDisabled"
          class="form-group"
          style="margin-bottom: 10px"
          [ngClass]="{
            'has-error has-danger':
              !phone.valid && (phone.dirty || phone.touched)
          }"
        >
          <label>Phone number</label>
          <input
            class="form-control"
            minlength="10"
            placement="top"
            ngbTooltip="Phone number"
            placeholder="e.g.(489) 797-3417"
            type="text"
            name="phone"
            autocomplete="off"
            [(ngModel)]="setupPasswordForm.phone"
            #phone="ngModel"
            [textMask]="{ mask: utility.maskPhone }"
            required
            [disabled]="isPhoneDisabled"
          />
          <div class="pre-icon os-icon os-icon-phone"></div>
          <div
            *ngIf="!phone.valid && (phone.dirty || phone.touched)"
            class="help-block form-text with-errors form-control-feedback"
          >
            Phone must be at least 10 digits long.
          </div>
        </div>
        <p
          class="manual-verification__header__text text-center"
          *ngIf="isPhoneDisabled && !availableSignUpByEmal()"
        >
          Please select Text Message or Phone Call and verify your phone
          <b>{{ objPassInfo.msk_phone }}</b>
        </p>
        <p
          class="manual-verification__header__text text-center"
          *ngIf="!availableSignUpByEmal()"
        >
          How would you like to receive your verification code?
        </p>
        <div class="text-center" *ngIf="availableSignUpByEmal()">
          <button
            class="mb-3 btn btn-primary"
            type="button"
            style="display: inline-block"
            (click)="sendVerifyTextCode()"
            [disabled]="
              !setupPasswordForm.cpassword ||
              !setupPasswordForm.password ||
              !setupPasswordForm.phone ||
              timerService.isTimerRun
            "
          >
            Send Verification Code &nbsp;<span
              [innerHTML]="timerService.getRemainigTime()"
            ></span>
          </button>
        </div>
        <div class="d-flex" *ngIf="!availableSignUpByEmal()">
          <button
            class="mr-3 mb-3 btn btn-primary"
            type="button"
            style="display: inline-block; margin: auto"
            (click)="sendVerifyTextCode()"
            [disabled]="
              !setupPasswordForm.cpassword ||
              !setupPasswordForm.password ||
              !setupPasswordForm.phone ||
              timerService.isTimerRun
            "
          >
            Text Message &nbsp;<span
              [innerHTML]="timerService.getRemainigTime()"
            ></span>
          </button>
          <button
            class="mr-3 mb-3 btn btn-primary"
            type="button"
            style="display: inline-block; margin: auto"
            (click)="sendVerifyVoiceCode()"
            [disabled]="
              !setupPasswordForm.cpassword ||
              !setupPasswordForm.password ||
              !setupPasswordForm.phone ||
              timerService.isTimerRun
            "
          >
            Phone Call
            <i
              aria-hidden="true"
              class="fa fa-question-circle"
              placement="top"
              ngbTooltip="If you have a robo call blocker on your landline this option will not work"
            >
            </i>
            &nbsp;<span [innerHTML]="timerService.getRemainigTime()"></span>
          </button>
        </div>

        <div
          class="form-group"
          [ngClass]="{
            'has-error has-danger':
              !phone_pin.valid && (phone_pin.dirty || phone_pin.touched)
          }"
          [hidden]="!setupPasswordForm.verifyCodeSend"
          style="margin-bottom: 0px"
        >
          <label>Verification Code</label>
          <input
            class="form-control"
            placement="top"
            ngbTooltip="Verification Code"
            placeholder="e.g.123456"
            type="text"
            name="phone_pin"
            [(ngModel)]="setupPasswordForm.phone_pin"
            #phone_pin="ngModel"
            required
            maxlength="6"
            minlength="6"
            appNumbers
            autocomplete="off"
            id="phone_pin"
          />
          <div class="pre-icon"><i class="icon-feather-hash"></i></div>
          <div
            *ngIf="!phone_pin.valid && (phone_pin.dirty || phone_pin.touched)"
            class="help-block form-text with-errors form-control-feedback"
          >
            <span *ngIf="phone_pin.errors.required">
              Verification Code is required.
            </span>
            <span *ngIf="phone_pin.errors.minlength">
              Verification Code must be at least 6 digits long.
            </span>
          </div>
        </div>
        <button
          style="
            color: #1071ef;
            text-decoration: underline;
            font-size: 12px;
            padding: 0;
          "
          type="button"
          class="btn btn-link"
          (click)="sendVerifyTextCode()"
          *ngIf="setupPasswordForm.verifyCodeSend && isSendTextMessage"
          [disabled]="timerService.isTimerRun"
        >
          Re-send Code&nbsp;<span
            [innerHTML]="timerService.getRemainigTime()"
          ></span>
        </button>
        <button
          style="
            color: #1071ef;
            text-decoration: underline;
            font-size: 12px;
            padding: 0;
          "
          type="button"
          class="btn btn-link"
          (click)="sendVerifyVoiceCode()"
          *ngIf="setupPasswordForm.verifyCodeSend && isSendVoiceMessage"
          [disabled]="timerService.isTimerRun"
        >
          Re-send Code&nbsp;<span
            [innerHTML]="timerService.getRemainigTime()"
          ></span>
        </button>

        <span style="display: none"
          ><countdown-timer
            *ngIf="timerService.isTimerRun"
            [countDownTimerConfig]="timerService.configCD"
          ></countdown-timer
        ></span>

        <div
          *ngIf="errorService.isError"
          class="alert alert-danger"
          role="alert"
          [innerHTML]="errorService.errorMessage"
        ></div>
        <div
          *ngIf="errorService.isSuccess"
          class="alert alert-success"
          role="alert"
          [innerHTML]="errorService.successMessage"
        ></div>
        <div class="text-center" style="margin-top: 20px">
          <button
            [disabled]="loading || !f.form.valid"
            type="submit"
            class="btn btn-primary"
            style="display: inline-block"
          >
            Submit & Verify
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
